@import "../../../../themes/wcmc_base/scss/_variables";
@import "../../../../themes/wcmc_base/scss/_mixins";

/* =============================================================================
   ~Headshots
   ========================================================================== */

  .headshot-grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
    @include clearfix;
  }

  .headshot-entry {
    width: 144px;
    margin-right: 25px;
    @include breakpoint($bp1) {
      float: left;
    }
  }

  .headshot {
    width: 144px;
    height: auto;
    margin-bottom: 10px;
  }

  .headshot-details {
    width: 144px;
  }

  .headshot-details p {
    @include font-size(1.2);
    margin: 0;
    padding: 0 0 5px;
    line-height: 17px;
  }

  .headshot-details .name {
    font-weight: bold;
  }
