/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/
/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stretchy {
  width: 100%;
  font-size: 0px;
}

.float-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  float: left;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* =============================================================================
   ~Headshots
   ========================================================================== */
.headshot-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  *zoom: 1;
}

.headshot-grid:before, .headshot-grid:after {
  content: "";
  display: table;
}

.headshot-grid:after {
  clear: both;
}

.headshot-entry {
  width: 144px;
  margin-right: 25px;
}

.lt-ie9 .headshot-entry {
  float: left;
}

@media screen and (min-width: 600px) {
  .headshot-entry {
    float: left;
  }
}

.headshot {
  width: 144px;
  height: auto;
  margin-bottom: 10px;
}

.headshot-details {
  width: 144px;
}

.headshot-details p {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0;
  padding: 0 0 5px;
  line-height: 17px;
}

.headshot-details .name {
  font-weight: bold;
}
