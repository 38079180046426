/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/

@mixin box-sizing ($box) {
//  content-box | border-box | inherit
  -moz-box-sizing: $box;
  box-sizing: $box;
}
   // Legacy support for inline-block in IE7 (maybe IE6)
@mixin inline-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
}
// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  *zoom:1;

  &:before,
  &:after {
    content:"";
  display:table;
  }
  &:after {
    clear:both;
  }
}

@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}

@mixin hide-text {
  color:            transparent;
  font:             0/0 a;
  text-shadow:      none;
}

// HiDPI mixin. Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)
@mixin hidpi($ratio: 1.3) {
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: #{round($ratio*96)}dpi),
  only screen and (min-resolution: #{$ratio}dppx) {
    @content;
  }
}

@mixin retina-image($filename, $background-size, $extension: png, $retina-filename: null, $retina-suffix: _2x, $asset-pipeline: false) {
  @if $asset-pipeline {
    background-image: image-url("#{$filename}.#{$extension}");
  }
  @else {
    background-image:       url("#{$filename}.#{$extension}");
  }

  @include hidpi {
    @if $asset-pipeline {
      @if $retina-filename {
        background-image: image-url("#{$retina-filename}.#{$extension}");
      }
      @else {
        background-image: image-url("#{$filename}#{$retina-suffix}.#{$extension}");
      }
    }

    @else {
      @if $retina-filename {
        background-image: url("#{$retina-filename}.#{$extension}");
      }
      @else {
        background-image: url("#{$filename}#{$retina-suffix}.#{$extension}");
      }
    }
  }
  background-size: $background-size;
}

@mixin background-triangle($color:black){
  @if $color == black{
      background: url($image-location+"sprites/downarrow.png") no-repeat;
    }
    @else if $color == white{
      background: url($image-location+"sprites/downarrow_white.png") no-repeat;
    }
      background-position: right;
      background-size: 20px 10px;
      /* IE8*/
      // -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='wcmcnavigation/sites/all/themes/wcmc_base/images/sprites/downarrow_white.png', sizingMethod='scale')";
      /* Pre IE8*/
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src=$image-location+'sprites/downarrow_white.png', sizingMethod='scale');

}

@mixin font-size($size:1.6, $line: $size * 1.5){
  font-size:   ($size * 10) + px;
  font-size:   $size + rem;
}

@mixin box-sizingIEtoo($box) {
    @include box-sizing($box);
    *behavior: url(/profiles/wcmc/themes/wcmc_base/css/boxsizing.htc);
}

@mixin border-radius($radius: 8px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
 $params: $shadow1;
  @if $shadow2
    { $params: $shadow1, $shadow2; }
    @if $shadow3 != false
      { $params: $shadow1, $shadow2, $shadow3; }
      @if $shadow4 != false
        { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
        @if $shadow5 != false
          { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }

  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

@mixin transition($vars...) {
    -webkit-transition: $vars;
    -moz-transition: $vars;
    -ms-transition: $vars;
    -o-transition: $vars;
    transition: $vars;
}

@mixin triangle($width: 20px, $height: 20px, $direction: up, $color: red){

  width: 0;
  height: 0;
  content: ' ';

  // Right
  @if ($direction == right){
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color;
    border-right-style: solid;
  }
  // Left
  @if ($direction == left){
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
  }
  // Up
  @if ($direction == up){
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
  }

  // Down
  @if ($direction == down){
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
  }
}
//Pass this mixin 1 variable for min-width, 2 for min and max.
//The idea is to use single breakpoint if you want it to cascade up, so that IE7,8 get them.
//Everything passed as @content gets wrapped in a media query, and duplicated without query,
//prefixed by .lt-ie9 so that oldIE gets the styles.
@mixin breakpoint($bp) {
  @if (length($bp) == 2){
    $upperBreakPoint: nth($bp, 2) - 1;
    @media screen and (min-width: nth($bp, 1)) and (max-width: $upperBreakPoint){
      @content;
    }
  }
  @else{
    .lt-ie9 & { @content ;}
    @media screen and (min-width: $bp) { @content; }
  }
}

@mixin wcmc-logo($color: "black"){
  @include retina-image($image-location + 'WCMC_web_' + $color, 284px 62px, png);
  background-repeat: no-repeat;
}

@mixin nyp-logo($color: "red"){
  @include retina-image($image-location + 'NYP_logo_' + $color, 270px, png);
  background-position: 50%;
  background-repeat: no-repeat;
  min-width: 270px;
  min-height: 64px;
}

@mixin wcmc-emblem($color: "black"){
  @include retina-image($image-location + 'WCMC-EMBLEM_web_' + $color, $emblem-size $emblem-size, png);
  background-repeat: no-repeat;
}

/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stretchy{
    width: 100%;
    font-size: 0px;
}

.float-block{
  @include inline-block;
  float: left;
}

.clearfix{
  @include clearfix;
}
